<template>
  <transition name="players-page-prompt">
    <div
      v-show="numOfSelectedPlayers"
      class="players-page-prompt"
    >
      <div class="players-page-prompt-box">
        <span>
          Players selected:
          <strong>{{ numOfSelectedPlayers }}</strong>
        </span>
      </div>

      <div
        v-if="route.name !== 'events_full_offer'"
        class="players-page-prompt-box"
      >
        <Button
          variant="primary"
          @click="toggleMarketsModal"
        >
          Manage markets
        </Button>
      </div>
    </div>
  </transition>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import Button from '@/components/common/Button';

export default {
  emits: ['onAssignMarkets'],
  components: {
    Button,
  },
  setup(_, { emit }) {
    const store = useStore();
    const route = useRoute();

    const numOfSelectedPlayers = computed(() => store.getters.playersForMarketsPopupList.length);

    const toggleMarketsModal = () => emit('onAssignMarkets');

    return {
      route,
      numOfSelectedPlayers,
      toggleMarketsModal,
    };
  },
};
</script>

<style lang="scss">
.players-page-prompt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 12px;
  position: absolute;
  background: #FFFFFF;
  border: 1px solid $gray600;
  box-sizing: border-box;
  box-shadow: 0px 8px 12px -6px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  left: 50%;
  transform: translateX(-50%);
  transform-origin: center;
  height: 56px;
  max-height: 56px;
  min-height: 56px;
  bottom: 4px;
  z-index: 999;
}

.players-page-prompt-enter-active {
  animation: players-page-prompt-bounce-in .5s;
}

.players-page-prompt-leave-active {
  animation: players-page-prompt-bounce-in .25s reverse;
}

@keyframes players-page-prompt-bounce-in {
  0% {
    transform: translateY(100%) translateX(-50%);
  }
  50% {
    transform: translateY(-10%) translateX(-50%);
  }
  100% {
    transform: translateY(0%) translateX(-50%);
  }
}

.players-page-prompt-box {
  display: flex;
  align-items: center;
}
</style>
