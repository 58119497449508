<template>
  <Modal
    title="Assigning markets"
    :visible="isVisible"
    no-close-btn
    @close="onModalClose"
  >
    <template #subtitle>
      <div class="modal-subtitle">
        <div
          class="team-players-to-add-markets"
          v-if="teamAPlayersToAdd"
        >
          <p>
            <span class="teamLabel">
              {{ teamLabels.teamA }}:
            </span>
            <span class="playersNames">
              {{ teamAPlayersToAdd }}
            </span>
          </p>
        </div>
        <div
          class="team-players-to-add-markets"
          v-if="teamBPlayersToAdd"
        >
          <p>
            <span class="teamLabel">
              {{ teamLabels.teamB }}:
            </span>
            <span class="playersNames">
              {{ teamBPlayersToAdd }}
            </span>
          </p>
        </div>
      </div>
    </template>
    <div
      v-if="!availableMarkets.length && !isMarketsLoading && !marketSearch"
      class="no-players-message"
    >
      <p>No markets for selected players</p>
    </div>
    <div
      v-else
      class="modal-data"
    >
      <div class="search-field-container">
        <TextInput
          class="search-field"
          :model-value="marketSearch"
          @update:modelValue="updateSearch"
          icon="search"
          placeholder="Search"
          small
        />
      </div>
      <div
        v-if="!availableMarketsFiltered.length && !isMarketsLoading"
        class="no-players-message"
      >
        <p>No search results</p>
      </div>
      <div class="items-to-add">
        <Spinner
          v-if="isMarketsLoading"
          small
        />
        <div
          v-else
          v-for="item in availableMarketsFiltered"
          :key="item"
          class="market-item"
        >
          <div class="checkbox-item">
            <Checkbox
              :model-value="isMarketAdded(item.value)"
              :disabled="isMarketDisabled(item.value)"
              :intermediate="isMarketPartialyAdded(item.value)"
              @update:modelValue="marketToggled(item)"
            />
            <p @click="marketToggled(item)">
              {{ item.label }}
            </p>
          </div>
          <div
            v-if="numOfPlayerWithSubmittedMarket(item.value) && !isMarketDisabled(item.value)"
            class="players-with-submited-market"
          >
            <Tooltip
              text="Some players have this market already submitted, so you won't be able to remove them."
              left
            >
              <Icon name="info" />
            </Tooltip>
            <p><span class="num-of-players-submitted">{{ numOfPlayerWithSubmittedMarket(item.value) }}</span> submitted</p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="availableMarkets.length"
      class="modal-footer"
    >
      <span
        @click="toggleSelectAll"
        :class="{ 'deselect-active': !isSelectAllActive }"
      >
        {{ isSelectAllActive ? "Select all" : "Deselect all" }}
      </span>
      <div class="modal-footer-right-section">
        <span
          @click="onModalClose"
          class="dismiss-btn"
        >
          Dismiss
        </span>
        <Button
          variant="primary"
          :disabled="addMarketsDisabled"
          @click="addSelectedMarkets"
        >
          Save changes
        </Button>
      </div>
    </div>
  </Modal>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import {
  find,
  filter,
  forEach,
  map,
  includes,
  toLower,
  cloneDeep,
  reduce,
  every,
  some,
} from 'lodash';
import Modal from '@/components/common/Modal';
import Button from '@/components/common/Button';
import TextInput from '@/components/common/TextInput';
import Spinner from '@/components/common/Spinner';
import Checkbox from '@/components/common/Checkbox';
import Icon from '@/components/common/Icon';
import Tooltip from '@/components/common/Tooltip';
import MarketMapper from '@/services/helpers/market-mapper';
import { formatPlayerName } from '@/services/helpers/player-setup-mapper';
import { getBaseballPlayerMarketCodesByType, getAFMarketCodesByType } from '@/components/player-setup/params-helper';
import sportIds from '@/services/helpers/sports';

const {
  BASEBALL_ID,
  FOOTBALL_ID,
} = sportIds;

export default {
  emits: ['onModalClose', 'onAddSelectedMarkets'],
  components: {
    Modal,
    Button,
    TextInput,
    Spinner,
    Checkbox,
    Icon,
    Tooltip,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    availableMarkets: {
      type: Array,
      default: () => [],
    },
    submittedMarkets: {
      type: Array,
      default: () => [],
    },
    sportId: {
      type: String,
      default: () => '',
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const isMarketsLoading = computed(() => store.getters.isMarketsLoading);
    const marketSearch = ref('');
    const availableMarketsFiltered = computed(() => filter(
      props.availableMarkets,
      (market) => includes(toLower(market.label), toLower(marketSearch.value)),
    ));
    const playersToAddMarketsTo = computed(() => store.getters.playersForMarketsPopupList);
    const teamLabels = computed(() => ({
      teamA: store.getters.playerSetupData?.teamA?.name,
      teamB: store.getters.playerSetupData?.teamB?.name,
    }));
    const teamAPlayersToAdd = computed(() => {
      const playersNames = reduce(
        filter(store.getters.playerSetupTableData?.teamA, (player) => includes(playersToAddMarketsTo.value, player.playerId)),
        (allNames, player) => `${allNames}${formatPlayerName(player.playerName)},`, '',
      );
      return playersNames?.substring(0, playersNames.length - 1) || '';
    });
    const teamBPlayersToAdd = computed(() => {
      const playersNames = reduce(
        filter(store.getters.playerSetupTableData?.teamB, (player) => includes(playersToAddMarketsTo.value, player.playerId)),
        (allNames, player) => `${allNames}${formatPlayerName(player.playerName)},`, '',
      );
      return playersNames?.substring(0, playersNames.length - 1) || '';
    });
    const updateSearch = (val) => { marketSearch.value = val; };

    // Here we get all the players that we want to batch add markets to
    // We map marketCodeDetails from each player
    // Then we use additional map to extract only the fields we need (marketCode, isLocked)
    const allPlayersAddedMarketsCodes = computed(() => map(
      map(filter([
        ...store.getters.playerSetupTableData?.teamA || [],
        ...store.getters.playerSetupTableData?.teamB || [],
      ], (player) => includes(playersToAddMarketsTo.value, player.playerId)),
      (player) => player.marketCodeDetails), (markets) => map(markets, (market) => ({
        marketCode: market.marketCode,
        isLocked: market.isLocked,
      })),
    ));

    // We fill the array with the markets that are already added
    const addedMarkets = ref(map(
      filter(props.availableMarkets,
        ({ value }) => every(allPlayersAddedMarketsCodes.value,
          (marketCodes) => includes(map(marketCodes, (market) => market.marketCode), value))), (market) => market.value,
    ) || []);
    const removedMarkets = ref([]);

    const isSelectAllActive = computed(() => props.availableMarkets.length !== addedMarkets.value.length);

    // To check if the save changes button is enabled
    // We use addedMarkets and removedMarkets array to check that
    // But we need to exclude already added markets from the calculation
    const addMarketsDisabled = computed(() => !filter(
      addedMarkets.value,
      (marketCode) => some(allPlayersAddedMarketsCodes.value,
        (markets) => !includes(map(markets, (market) => market.marketCode), marketCode)),
    )?.length
    && !filter(
      removedMarkets.value,
      (marketCode) => some(allPlayersAddedMarketsCodes.value,
        (markets) => includes(map(markets, (market) => market.marketCode), marketCode)),
    )?.length);
    const isMarketAdded = (marketCode) => !!find(addedMarkets.value, (value) => value === marketCode);

    // Here we check if the market is partialy added
    // For that condition to be true:
    // Market should be assigned to at least 1 player
    // Market should not be assigned to all players
    // Market should not be inside of the added market list
    // Market should not be inside of the removed market list
    const isMarketPartialyAdded = (marketCode) => some(allPlayersAddedMarketsCodes.value,
      (marketCodes) => includes(map(marketCodes, (market) => market.marketCode), marketCode))
      && !every(allPlayersAddedMarketsCodes.value,
        (marketCodes) => includes(map(marketCodes, (market) => market.marketCode), marketCode))
      && !includes(addedMarkets.value, marketCode)
      && !includes(removedMarkets.value, marketCode);

    const isMarketDisabled = (marketCode) => every(allPlayersAddedMarketsCodes.value,
      (markets) => some(markets,
        (market) => market.marketCode === marketCode && !market.isLocked));

    const numOfPlayerWithSubmittedMarket = (marketCode) => filter(allPlayersAddedMarketsCodes.value,
      (markets) => some(markets,
        (market) => market.marketCode === marketCode && !market.isLocked))?.length;

    const marketToggled = (marketToToggle) => {
      if (isMarketDisabled(marketToToggle.value)) return;
      const market = find(addedMarkets.value, (value) => value === marketToToggle.value);
      if (!market) {
        addedMarkets.value.push(marketToToggle.value);
        removedMarkets.value = filter(removedMarkets.value, (value) => value !== marketToToggle.value);
      } else {
        addedMarkets.value = filter(addedMarkets.value, (value) => value !== marketToToggle.value);
        removedMarkets.value.push(marketToToggle.value);
      }
    };

    const toggleSelectAll = () => {
      if (isSelectAllActive.value) {
        const marketsValues = map(props.availableMarkets, (market) => market.value);
        forEach(marketsValues, (val) => {
          const alreadyAdded = find(addedMarkets.value, (value) => value === val);
          if (!alreadyAdded) addedMarkets.value.push(val);
        });
      } else {
        addedMarkets.value = filter(addedMarkets.value, (marketCode) => isMarketDisabled(marketCode));
        removedMarkets.value = map(
          filter(props.availableMarkets, (market) => !isMarketDisabled(market.value)),
          (market) => market.value,
        );
      }
    };

    const toggleMarketsToPlayer = (teamPlayers, player) => {
      const playerToEdit = find(teamPlayers, { playerId: player.playerId });
      let marketsToAdd = addedMarkets.value;
      if (props.sportId === BASEBALL_ID) {
        marketsToAdd = map(getBaseballPlayerMarketCodesByType({
          markets: map(marketsToAdd, (market) => ({ marketCode: market })),
          isPitcher: playerToEdit.isPitcher,
          isHitter: playerToEdit.isHitter,
        }), (market) => market.marketCode);
      } else if (props.sportId === FOOTBALL_ID) {
        marketsToAdd = map(getAFMarketCodesByType({
          markets: map(marketsToAdd, (market) => ({ marketCode: market })),
          availableMarketsPlayerTypes: store.getters.availableMarketsPlayerTypes,
          isQuarterback: playerToEdit?.isQuarterback,
          isOffensiveNonQB: playerToEdit?.isOffensiveNonQB,
          isKicker: playerToEdit?.isKicker,
        }), (market) => market.marketCode);
      }
      let marketsToRemove = [];

      if (marketsToAdd.length) {
        // Filtering markets that are already assigned to a player
        marketsToAdd = filter(marketsToAdd,
          (marketCode) => !includes(
            map(player?.marketCodeDetails, (market) => market.marketCode),
            marketCode,
          ));
      }
      if (removedMarkets.value.length) {
        marketsToRemove = filter(removedMarkets.value,
          (marketCode) => includes(
            map(filter(player?.marketCodeDetails, (market) => market.isLocked), (market) => market.marketCode),
            marketCode,
          ));
      }

      // Add markets to the player
      if (marketsToAdd.length) {
        playerToEdit.marketCodeDetails = [
          ...playerToEdit.marketCodeDetails,
          ...map(marketsToAdd, (market) => ({
            marketCode: market,
            suspended: false,
            marketName: MarketMapper.playersMarketName(market),
            isLocked: true,
          })),
        ];
      }

      // Remove markets from the player
      if (marketsToRemove.length) {
        playerToEdit.marketCodeDetails = filter(
          playerToEdit.marketCodeDetails,
          (market) => !includes(marketsToRemove, market.marketCode),
        );
      }
    };

    const addSelectedMarkets = () => {
      const playersData = cloneDeep(store.getters.playerSetupTableData);
      let teamAPlayers = playersData?.teamA;
      let teamBPlayers = playersData?.teamB;

      // Filtering players that are selected for markets assign
      teamAPlayers = filter(teamAPlayers, (player) => includes(playersToAddMarketsTo.value, player.playerId));
      teamBPlayers = filter(teamBPlayers, (player) => includes(playersToAddMarketsTo.value, player.playerId));

      // Assigning markets to teamA players
      forEach(teamAPlayers, (player) => {
        toggleMarketsToPlayer(teamAPlayers, player);
      });

      // Assigning markets to teamB players
      forEach(teamBPlayers, (player) => {
        toggleMarketsToPlayer(teamBPlayers, player);
      });

      store.dispatch('setPlayerSetupTableData', playersData);
      marketSearch.value = '';
      addedMarkets.value = [];
      removedMarkets.value = [];
      emit('onAddSelectedMarkets');
      store.dispatch('setPlayerForMarketsPopupList', []);
    };

    const onModalClose = () => {
      emit('onModalClose');
    };

    return {
      isMarketsLoading,
      availableMarketsFiltered,
      addMarketsDisabled,
      marketSearch,
      isSelectAllActive,
      teamLabels,
      teamAPlayersToAdd,
      teamBPlayersToAdd,
      updateSearch,
      isMarketAdded,
      marketToggled,
      toggleSelectAll,
      onModalClose,
      addSelectedMarkets,
      isMarketPartialyAdded,
      isMarketDisabled,
      numOfPlayerWithSubmittedMarket,
    };
  },
};
</script>

<style lang="scss">
  .modal-subtitle {
    display: flex;
    flex-direction: column;
    padding-top: 4px;
    .team-players-to-add-markets {
      width: 100%;
      font-size: 12px;
      font-weight: 400;
      color: $gray800;
      overflow: hidden;
      p {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .teamLabel {
          font-weight: 600;
        }
      }
    }
  }
  .modal-data {
    .items-to-add {
      .market-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .players-with-submited-market {
        display: flex;
        align-items: center;
        color: $gray800;
        font-size: 12px;
        .tooltip {
          .tooltip-text {
            width: 300px;
            white-space: wrap;
            padding: 4px 12px;
            height: auto;
          }
          .icon {
            cursor: pointer;
            svg {
              height: 14px;
              width: 14px;
            }
          }
        }
        .num-of-players-submitted {
          display: inline-block;
          width: 13px;
          text-align: right;
        }
      }
    }
  }
</style>
