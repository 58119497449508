<template>
  <Modal
    :title="`${playerName} market list`"
    subtitle="Choose which markets you want to offer for player."
    :visible="isVisible"
    @close="onModalClose"
  >
    <div
      v-if="!availableMarkets.length && !isMarketsLoading && !marketSearch"
      class="no-players-message"
    >
      <p>No markets for selected player</p>
    </div>
    <div
      v-else
      class="modal-data"
    >
      <div class="search-field-container">
        <TextInput
          class="search-field"
          :model-value="marketSearch"
          @update:modelValue="updateSearch"
          icon="search"
          placeholder="Search"
          small
        />
      </div>
      <div
        v-if="!availableMarketsFiltered.length && !isMarketsLoading"
        class="no-players-message"
      >
        <p>No search results</p>
      </div>
      <div class="items-to-add">
        <Spinner
          v-if="isMarketsLoading"
          small
        />
        <div
          v-else
          v-for="item in availableMarketsFiltered"
          :key="item"
          class="checkbox-item"
        >
          <Checkbox
            :model-value="isMarketAdded(item.value)"
            :disabled="item.disabled"
            @update:modelValue="marketToggled(item)"
          />
          <p @click="marketToggled(item)">
            {{ item.label }}
          </p>
        </div>
      </div>
    </div>
    <div
      v-if="availableMarkets.length"
      class="modal-footer"
    >
      <span
        @click="toggleSelectAll"
        :class="{ 'deselect-active': !isSelectAllActive }"
      >
        {{ isSelectAllActive ? "Select all" : "Deselect all" }}
      </span>
      <div class="modal-footer-right-section">
        <span
          @click="onModalClose"
          class="dismiss-btn"
        >
          Dismiss
        </span>
        <Button
          variant="primary"
          :disabled="addMarketsDisabled"
          @click="addSelectedMarkets"
        >
          Update list
        </Button>
      </div>
    </div>
  </Modal>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import {
  find,
  filter,
  forEach,
  map,
  cloneDeep,
  includes,
  toLower,
} from 'lodash';
import Modal from '@/components/common/Modal';
import Button from '@/components/common/Button';
import TextInput from '@/components/common/TextInput';
import Spinner from '@/components/common/Spinner';
import Checkbox from '@/components/common/Checkbox';
import MarketMapper from '@/services/helpers/market-mapper';

export default {
  emits: ['onModalClose', 'onAddSelectedMarkets'],
  components: {
    Modal,
    Button,
    TextInput,
    Spinner,
    Checkbox,
  },
  props: {
    playerName: {
      type: String,
      default: '',
    },
    playerId: {
      type: String,
      default: '',
    },
    teamLabel: {
      type: String,
      default: '',
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    availableMarkets: {
      type: Array,
      default: () => [],
    },
    submittedMarkets: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const isMarketsLoading = computed(() => store.getters.isMarketsLoading);
    const addedMarkets = ref(map(props.submittedMarkets, (market) => market.marketCode) || []);
    const marketsToRemove = ref([]);
    const marketSearch = ref('');
    const availableMarketsFiltered = computed(() => filter(
      props.availableMarkets,
      (market) => includes(toLower(market.label), toLower(marketSearch.value)),
    ));
    const isSelectAllActive = computed(() => props.availableMarkets.length !== addedMarkets.value.length);
    const addMarketsDisabled = computed(() => addedMarkets.value?.length === props.submittedMarkets?.length);
    const updateSearch = (val) => { marketSearch.value = val; };
    const isMarketAdded = (val) => !!find(addedMarkets.value, (value) => value === val);
    const marketToggled = (marketToToggle) => {
      if (marketToToggle.disabled) return;
      const market = find(addedMarkets.value, (value) => value === marketToToggle.value);
      if (!market) {
        addedMarkets.value.push(marketToToggle.value);
      } else {
        addedMarkets.value = filter(addedMarkets.value, (value) => value !== marketToToggle.value);
        marketsToRemove.value.push(marketToToggle.value);
      }
    };
    const toggleSelectAll = () => {
      if (isSelectAllActive.value) {
        const marketsValues = map(props.availableMarkets, (market) => market.value);
        forEach(marketsValues, (val) => {
          const alreadyAdded = find(addedMarkets.value, (value) => value === val);
          if (!alreadyAdded) addedMarkets.value.push(val);
        });
      } else {
        addedMarkets.value = map(filter(props.submittedMarkets, (market) => !market.isLocked), (market) => market.marketCode) || [];
        marketsToRemove.value = map(filter(props.submittedMarkets, (market) => market.isLocked), (market) => market.marketCode) || [];
      }
    };

    const excludeRemovedMarkets = (markets) => filter(
      markets,
      (market) => !includes(marketsToRemove.value, market.marketCode || market),
    );

    const addSelectedMarkets = () => {
      const playersData = cloneDeep(store.getters.playerSetupTableData);
      const playersByTeamData = playersData?.[props.teamLabel];
      const playerToAddMarketsTo = find(playersByTeamData, { playerId: props.playerId });
      const marketsToAdd = filter(
        addedMarkets.value,
        (marketCode) => !includes(map(props.submittedMarkets, (market) => market.marketCode), marketCode),
      );
      playerToAddMarketsTo.marketCodeDetails = [
        ...excludeRemovedMarkets(playerToAddMarketsTo.marketCodeDetails),
        ...map(excludeRemovedMarkets(marketsToAdd), (market) => ({
          marketCode: market,
          suspended: false,
          marketName: MarketMapper.playersMarketName(market),
          isLocked: true,
        })),
      ];
      store.dispatch('setPlayerSetupTableData', playersData);
      marketsToRemove.value = [];
      marketSearch.value = '';
      emit('onAddSelectedMarkets');
    };

    const onModalClose = () => {
      addedMarkets.value = map(props.submittedMarkets, (market) => market.marketCode) || [];
      marketsToRemove.value = [];
      emit('onModalClose');
    };

    return {
      isMarketsLoading,
      availableMarketsFiltered,
      addMarketsDisabled,
      marketSearch,
      isSelectAllActive,
      updateSearch,
      isMarketAdded,
      marketToggled,
      toggleSelectAll,
      onModalClose,
      addSelectedMarkets,
    };
  },
};
</script>

<style></style>
