<template>
  <Modal
    :title="`Add players for ${teamName}`"
    subtitle="Choose players you want to offer."
    :visible="isVisible"
    @close="onModalClose"
  >
    <ParamsTabs
      v-if="playerTabs.length"
      class="baseball-players-tabs"
      :tabs="playerTabs"
      :active-tab="activeTab"
      @onTabChange="onTabChange"
    />
    <div
      v-if="!playersToAdd.length && !playersListLoading && !playerSearch"
      class="no-players-message"
    >
      <p>
        No players for selected team
      </p>
      <Button
        icon="link-external"
        @click="openTeamEdit"
      >
        Team edit
      </Button>
    </div>
    <div
      v-else
      class="modal-data"
    >
      <div class="search-field-container">
        <TextInput
          class="search-field"
          :model-value="playerSearch"
          @update:modelValue="updateSearch"
          icon="search"
          placeholder="Search player"
          small
        />
        <Button
          icon="link-external"
          @click="openTeamEdit"
        >
          Team edit
        </Button>
      </div>
      <div
        v-if="!playersToAddFiltered.length && !playersListLoading"
        class="no-players-message"
      >
        <p>
          No search results
        </p>
      </div>
      <div class="items-to-add">
        <Spinner
          v-if="playersListLoading"
          small
        />
        <div
          v-for="item in playersToAddFiltered"
          :key="item.value"
          class="player-row"
        >
          <div class="checkbox-item">
            <Checkbox
              :model-value="isPlayerAdded(item.value)"
              @update:modelValue="playerToggled(item.value)"
            />
            <p
              @click="playerToggled(item.value)"
              :title="item.playerIndex"
            >
              {{ item.label }}
            </p>
          </div>
          <div class="player-info mapping-projections">
            <Tooltip
              :text="isPlayerMapped(item.value) ? 'Player is mapped for projections' : 'Player is not mapped for projections'"
              left
            >
              <Icon
                :name="isPlayerMapped(item.value) ? 'link-not-broken' : 'link-broken'"
                :class="{ 'is-mapped': isPlayerMapped(item.value) }"
              />
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="playersToAdd.length"
      class="modal-footer"
    >
      <span
        @click="toggleSelectAll"
        :class="{ 'deselect-active': !isSelectAllActive }"
      >
        {{ isSelectAllActive ? 'Select all' : 'Deselect all' }}
      </span>
      <Button
        variant="primary"
        :disabled="addPlayersDisabled"
        @click="addSelectedPlayers"
      >
        Add players
      </Button>
    </div>
  </Modal>
</template>

<script>

import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import {
  find, filter, map, forEach,
  includes, toLower,
} from 'lodash';
import Modal from '@/components/common/Modal';
import Button from '@/components/common/Button';
import TextInput from '@/components/common/TextInput';
import Checkbox from '@/components/common/Checkbox';
import Spinner from '@/components/common/Spinner';
import Icon from '@/components/common/Icon';
import Tooltip from '@/components/common/Tooltip';
import ParamsTabs from '../../ParamsTabs';

export default {
  components: {
    Modal,
    Button,
    TextInput,
    Checkbox,
    Spinner,
    ParamsTabs,
    Icon,
    Tooltip,
  },
  emits: [
    'onClose',
    'onAddSelectedPlayers',
    'onSelectedTabChange',
  ],
  props: {
    teamName: {
      type: String,
      default: () => '',
    },
    teamId: {
      type: String,
      default: () => '',
    },
    sportId: {
      type: String,
      default: () => '',
    },
    playersToAdd: {
      type: Array,
      default: () => [],
    },
    isVisible: {
      type: Boolean,
      default: () => false,
    },
    playerTabs: {
      type: Array,
      default: () => [],
      required: false,
    },
    activeTab: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const playersListLoading = computed(() => store.getters.teamSquadListLoading);
    const playerSearch = ref('');
    const playersToAddFiltered = computed(() => filter(
      props.playersToAdd,
      (player) => includes(toLower(player.label), toLower(playerSearch.value)),
    ));

    const addedPlayers = ref([]);
    const addPlayersDisabled = computed(() => !addedPlayers.value.length);
    const isSelectAllActive = computed(() => props.playersToAdd.length !== addedPlayers.value.length);
    const onModalClose = () => {
      emit('onClose');
      if (props.playerTabs.length) emit('onSelectedTabChange', props.playerTabs[0]);
    };
    const updateSearch = (val) => { playerSearch.value = val; };

    const isPlayerAdded = (val) => !!find(addedPlayers.value, (value) => value === val);
    const playerToggled = (val) => {
      const player = find(addedPlayers.value, (value) => value === val);
      if (!player) {
        addedPlayers.value.push(val);
      } else {
        addedPlayers.value = filter(addedPlayers.value, (value) => value !== val);
      }
    };

    const toggleSelectAll = () => {
      if (isSelectAllActive.value) {
        const playersValues = map(props.playersToAdd, (player) => player.value);
        forEach(playersValues, (val) => {
          const alreadyAdded = find(addedPlayers.value, (value) => value === val);
          if (!alreadyAdded) addedPlayers.value.push(val);
        });
      } else {
        addedPlayers.value = [];
      }
    };

    const openTeamEdit = () => {
      const { query } = route;
      const { href } = router.resolve({
        name: 'teamManualEdit',
        query: {
          ...query,
          ...(query.sport ? {} : { sport: props.sportId }),
          teamId: props.teamId,
        },
      });
      window.open(href, '_blank');
    };

    const addSelectedPlayers = () => {
      emit('onAddSelectedPlayers', addedPlayers.value);
      addedPlayers.value = [];
      playerSearch.value = '';
    };

    const onTabChange = (tab) => emit('onSelectedTabChange', tab);

    const playerSetupMappedPlayersForProjections = computed(() => map(store.getters.playerSetupMappedPlayersForProjections, (player) => player.playerId));
    const isPlayerMapped = (playerId) => includes(playerSetupMappedPlayersForProjections.value, playerId);

    return {
      playersToAddFiltered,
      playersListLoading,
      addPlayersDisabled,
      isSelectAllActive,
      playerSearch,
      onModalClose,
      updateSearch,
      isPlayerAdded,
      playerToggled,
      toggleSelectAll,
      addSelectedPlayers,
      onTabChange,
      isPlayerMapped,
      openTeamEdit,
    };
  },
};
</script>

<style lang="scss">
.baseball-players-tabs {
  padding: 0;
  height: auto;
  margin-bottom: 8px;
  .params-tabs-item {
    width: 100%;
  }
}
.search-field-container {
  display: flex;
  justify-content: space-between;
  gap: 4px;
  .search-field {
    flex: 1;
  }
}
.no-players-message {
  button {
    margin-top: 12px;
  }
}

.player-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 33px;

  .checkbox-item {
    margin-bottom: 0;
  }

  .player-action {
    display: flex;
  }

  .player-info {
    display: flex;

    &.mapping-projections {

      .tooltip-element {
        display: flex;
        .icon {
          width: 16px;
          height: 16px;

          svg {
            path {
              fill: #ff2e2d;
            }
          }

          &.is-mapped {
            svg {
              path {
                fill: #191414;;
              }
            }
          }
        }
      }
    }
  }
}
</style>
