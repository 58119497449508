<template>
  <AmericanFootballAddPlayersModal
    :team-name="teamData.name"
    :players-to-add="playersToAdd"
    :is-visible="isAddPlayersModalActive"
    :player-tabs="playerTypeTabs"
    :active-tab="activeTab"
    :team-id="teamData.teamId"
    @onClose="onModalClose"
    @onAddSelectedPlayers="addSelectedPlayers"
    @onSelectedTabChange="onTabChange"
  />
  <div class="list-of-players">
    <div class="section-header">
      <div class="title">
        <p>{{ data.name }}</p>
      </div>
      <div class="actions-section">
        <Button
          v-if="hasTableData"
          :disabled="isSuspendAllPlayersDisabled"
          @click="toggleAllPlayersSuspend"
          class="suspend-btn"
          :class="{'suspend-active': isSuspendAllPlayersActive}"
        >
          <Icon :name="isSuspendAllPlayersActive ? 'pause' : 'play'" />
          <span>{{ isSuspendAllPlayersActive ? 'Suspend all players' : 'Unsuspend all players' }}</span>
        </Button>
        <Button
          class="add-players-btn"
          variant="primary"
          @click="addPlayers"
        >
          <Icon name="user-plus-01" />
          <span>Players</span>
        </Button>
      </div>
    </div>
    <div
      class="section-content"
    >
      <Spinner v-if="isProjectedPlayerParamsDataLoading" />
      <AmericanFootballPlayerParamsByTeam
        v-else
        :team-data="playerSetupTableDataByTeam"
        :team-data-filtered="playerSetupTableDataByTeamFiltered"
        :team-label="teamData.teamLabel"
        :team-side="teamData.label"
        @onPlayersTypeTabChange="onPlayersTypeTabChange"
      />
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import {
  find, filter, map, includes, reduce, omit, toUpper, orderBy,
  cloneDeep, forEach, findIndex,
} from 'lodash';
import { useStore } from 'vuex';
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';
import Spinner from '@/components/common/Spinner';
import {
  footballPlayerParamsSetup,
  formatPlayerName,
} from '@/services/helpers/player-setup-mapper';

import {
  getSportNameBySportId,
} from '@/services/helpers/mappings-mapper';
import AmericanFootballAddPlayersModal from './AmericanFootballAddPlayersModal';
import AmericanFootballPlayerParamsByTeam from './AmericanFootballPlayerParamsByTeam';
import * as api from '@/services/api';

export default {
  emits: ['onPlayersTypeTabChange'],
  components: {
    Button,
    Icon,
    AmericanFootballAddPlayersModal,
    Spinner,
    AmericanFootballPlayerParamsByTeam,
  },
  props: {
    teamData: {
      type: Object,
      default: () => {},
    },
    eventId: {
      type: String,
      default: () => '',
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const searchValue = ref('');
    const isAddPlayersModalActive = ref(false);
    const data = computed(() => props.teamData);
    const playerSetupData = computed(() => store.getters.playerSetupData);
    const teamLineup = computed(() => (props.teamData?.label === 'Home' ? playerSetupData.value?.homeLineup : playerSetupData.value?.awayLineup));
    const playerSetupTableData = computed(() => store.getters.playerSetupTableData);
    const playerSetupTableDataByTeam = computed(() => map(playerSetupTableData.value?.[props.teamData?.teamLabel], (player) => (
      {
        ...player,
        isInTeamLineup: includes(map(teamLineup.value, ({ playerId }) => playerId), player.playerId),
      }
    )));
    const playerSetupTableDataByTeamFiltered = computed(() => filter(playerSetupTableDataByTeam.value,
      (player) => player.playerName?.toLowerCase().includes(searchValue.value?.toLowerCase())));
    const isSuspendAllPlayersActive = computed(() => find(playerSetupTableDataByTeam.value, ({ isSuspended, marketCodeDetails }) => !isSuspended
      && find(marketCodeDetails, ({ isLocked }) => !isLocked)));
    const isSuspendAllPlayersDisabled = computed(() => !find(playerSetupTableDataByTeam.value,
      ({ marketCodeDetails }) => find(marketCodeDetails, ({ isLocked }) => !isLocked)));
    const hasTableData = computed(() => playerSetupTableDataByTeam.value?.length);

    // Player add modal tabs config
    const playerTypeTabs = computed(() => {
      const tabs = [
        {
          key: 'quarterbacks',
          label: 'Quarterbacks',
        },
        {
          key: 'non-quarterbacks',
          label: 'Non-Quarterbacks',
        },
        {
          key: 'kickers',
          label: 'Kickers',
        },
      ];
      return tabs;
    });
    const activeTab = ref(playerTypeTabs.value[0]);
    const onTabChange = (tab) => { activeTab.value = tab; };
    const allSquadData = computed(() => playerSetupData.value?.[props.teamData?.teamLabel]?.squad);
    const squadTeamData = computed(() => {
      if (activeTab.value.key === 'quarterbacks') {
        return filter(allSquadData.value, ({ position }) => position === 'QUARTERBACK' || position === 'UNKNOWN' || !position);
      } if (activeTab.value.key === 'non-quarterbacks') {
        return filter(allSquadData.value, ({ position }) => position === 'OFFENSIVE_NON_QB' || position === 'UNKNOWN' || !position);
      } if (activeTab.value.key === 'kickers') {
        return filter(allSquadData.value, ({ position }) => position === 'KICKER' || position === 'UNKNOWN' || !position);
      }
      return [];
    });

    const allPlayersToAdd = computed(() => {
      const alreadyAddedQuarterbacksIds = map(filter(playerSetupTableDataByTeam.value, ({ isQuarterback }) => isQuarterback), ({ playerId }) => playerId);
      const alreadyAddedNonQuarterbacksIds = map(filter(playerSetupTableDataByTeam.value, ({ isOffensiveNonQB }) => isOffensiveNonQB), ({ playerId }) => playerId);
      const alreadyAddedKickersIds = map(filter(playerSetupTableDataByTeam.value, ({ isKicker }) => isKicker), ({ playerId }) => playerId);
      let alreadyAddedPlayers = [];
      if (activeTab.value.key === 'quarterbacks') {
        alreadyAddedPlayers = alreadyAddedQuarterbacksIds;
      } else if (activeTab.value.key === 'non-quarterbacks') {
        alreadyAddedPlayers = alreadyAddedNonQuarterbacksIds;
      } else if (activeTab.value.key === 'kickers') {
        alreadyAddedPlayers = alreadyAddedKickersIds;
      }
      const playerList = map(allSquadData.value, (player) => ({
        value: player.playerId,
        label: formatPlayerName(player.playerName),
        isInTeamLineup: includes(map(teamLineup.value, ({ playerId }) => playerId), player.playerId),
        isAlreadyAdded: includes(alreadyAddedPlayers, player.playerId),
        isQuarterback: includes(alreadyAddedQuarterbacksIds, player.playerId),
        isOffensiveNonQB: includes(alreadyAddedNonQuarterbacksIds, player.playerId),
        isKicker: includes(alreadyAddedKickersIds, player.playerId),
        playerIndex: player.playerIndex,
      }));
      return orderBy(playerList, 'label');
    });

    const playersToAdd = computed(() => filter(allPlayersToAdd.value, (player) => includes(map(squadTeamData.value, ({ playerId }) => playerId), player.value)));

    const isProjectedPlayerParamsDataLoading = ref(false);

    const addPlayers = () => {
      isAddPlayersModalActive.value = true;
      store.dispatch('loadTeamSquad', { teamId: data.value.teamId, sportId: data.value.sportId });
    };
    const onModalClose = () => { isAddPlayersModalActive.value = false; };

    const updateAlreadyAddedPlayers = (addedPlayers, updatedPlayers) => {
      const updatedTeamData = cloneDeep(playerSetupTableDataByTeam.value);
      forEach(addedPlayers, (playerId) => {
        const playerToUpdateIndex = findIndex(updatedTeamData, (player) => player.playerId === playerId);
        const updatedPlayer = find(updatedPlayers, (player) => player.value === playerId);
        const playerToUpdate = find(updatedTeamData, (player) => player.playerId === playerId);
        // We need to call footballPlayerParamsSetup and set isQuarterback and isOffensiveNonQB to true
        // So that we can get quarterback and nonQuarterback params
        // Then after that we set if the player really is a quarterback or a offensiveNonQB which will determine in which tab it will be shown
        // Mapper that is called on the submit will set params of quarterback or offensiveNonQB to null if the value is false
        const playerParams = footballPlayerParamsSetup(
          {
            ...playerToUpdate,
            isQuarterback: true,
            isOffensiveNonQB: true,
          },
        );
        const playerParamsUpdated = {
          ...playerParams,
          ...playerParams.playerParams,
          playerName: playerToUpdate.playerName,
          isQuarterback: playerToUpdate.isQuarterback || updatedPlayer.isQuarterback,
          isOffensiveNonQB: playerToUpdate.isOffensiveNonQB || updatedPlayer.isOffensiveNonQB,
          isKicker: playerToUpdate.isKicker || updatedPlayer.isKicker,
        };

        delete playerParamsUpdated.playerParams;
        updatedTeamData[playerToUpdateIndex] = playerParamsUpdated;
      });

      const playerSetupTableDataPayload = {
        ...playerSetupTableData.value,
        [props.teamData.teamLabel]: updatedTeamData,
      };
      store.dispatch('setPlayerSetupTableData', playerSetupTableDataPayload);
    };
    const addSelectedPlayers = async ({
      addedPlayers,
    }) => {
      onModalClose();
      const addedPlayersIds = map(addedPlayers, ({ value }) => value);
      const alreadyAddedPlayers = map(filter(playerSetupTableDataByTeam.value, ({ playerId }) => includes(addedPlayersIds, playerId)), ({ playerId }) => playerId);
      if (alreadyAddedPlayers.length) { updateAlreadyAddedPlayers(alreadyAddedPlayers, addedPlayers); }
      const newPlayersToAdd = filter(addedPlayers, (player) => !includes(alreadyAddedPlayers, player.value));

      isAddPlayersModalActive.value = false;
      const side = toUpper(props.teamData?.label);
      let playerParams = footballPlayerParamsSetup({ side, isQuarterback: true, isOffensiveNonQB: true });
      playerParams = {
        ...playerParams.playerParams,
        marketCodeDetails: playerParams.marketCodeDetails,
        isSuspended: playerParams.isSuspended,
      };
      let projectedPlayerParams = [];
      isProjectedPlayerParamsDataLoading.value = true;
      try {
        const paramsData = await api.getProjectedPlayerParams({
          sport: getSportNameBySportId(playerSetupData.value.sportId).toLowerCase(),
          eventId: playerSetupData.value?.eventId,
          addedPlayers: addedPlayersIds,
        });
        projectedPlayerParams = reduce(
          paramsData.projectedPlayerParams,
          (allPlayers, player) => ({
            ...allPlayers,
            [player.playerParams?.playerId]: player.playerParams,
          }), {},
        );
      } catch {
        projectedPlayerParams = null;
      }
      isProjectedPlayerParamsDataLoading.value = false;

      let nextPlayerIndex = orderBy(playerSetupTableDataByTeam.value, 'playerPositionIndex')[playerSetupTableDataByTeam.value?.length - 1]?.playerPositionIndex || 0;
      const addedPlayersProjectedParams = reduce(
        newPlayersToAdd || [],
        (allPlayers, player) => {
          const playerId = player.value;
          const playerParamsUpdated = cloneDeep(playerParams);
          const projectedPlayerParamsUpdated = cloneDeep(projectedPlayerParams ? omit(projectedPlayerParams[playerId], ['marketCodeDetails']) : {});
          const {
            isQuarterback, isOffensiveNonQB,
            isKicker, playerPositionIndex,
          } = player;
          const playerInfo = find(allSquadData.value, { playerId });
          nextPlayerIndex += 1;
          // Here we loop through the list of added players IDs
          // 1. playerParamsUpdated
          // All the player params with default values as a fallback
          // 2. playerInfo
          // We add playerInfo which contains playerName and playerIndex which we need
          // 3. projectedPlayerParamsUpdated
          // Player params projections fetched from an API
          return [
            ...allPlayers,
            ...[{
              ...playerParamsUpdated,
              ...playerInfo,
              ...projectedPlayerParamsUpdated,
              lineup: true,
              isQuarterback,
              isOffensiveNonQB,
              isKicker,
              playerPositionIndex: playerPositionIndex || nextPlayerIndex,
            }],
          ];
        },
        [],
      );
      const updatedTeamData = [
        ...(playerSetupTableDataByTeam.value ? playerSetupTableDataByTeam.value : []),
        ...addedPlayersProjectedParams,
      ];

      const playerSetupTableDataPayload = {
        ...playerSetupTableData.value,
        [props.teamData.teamLabel]: updatedTeamData,
      };
      store.dispatch('setPlayerSetupTableData', playerSetupTableDataPayload);
    };
    const toggleAllPlayersSuspend = () => {
      const action = isSuspendAllPlayersActive.value ? 'suspend' : 'unsuspend';
      store.dispatch('toggleAllPlayersSuspend', {
        teamLabel: data.value.teamLabel,
        action,
      });
    };
    const updatePlayerSearch = (value) => {
      searchValue.value = value;
    };

    const onPlayersTypeTabChange = (newActiveTab) => {
      emit('onPlayersTypeTabChange', newActiveTab);
    };

    return {
      data,
      hasTableData,
      isAddPlayersModalActive,
      playersToAdd,
      isProjectedPlayerParamsDataLoading,
      isSuspendAllPlayersActive,
      isSuspendAllPlayersDisabled,
      playerTypeTabs,
      activeTab,
      playerSetupTableDataByTeam,
      playerSetupTableDataByTeamFiltered,
      toggleAllPlayersSuspend,
      addPlayers,
      onModalClose,
      addSelectedPlayers,
      onTabChange,
      updatePlayerSearch,
      onPlayersTypeTabChange,
    };
  },
};
</script>

<style lang="scss">
.list-of-players {
  height: 50%;
  padding-top: 16px;
  position: relative;
  .section-title {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 8px;
    text-transform: uppercase;
    color: $primary500;
  }
  .section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    padding-right: 3px;
    margin-bottom: 12px;
    .title {
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
    }
  }
  .actions-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;

    .suspend-btn {
      &.suspend-active {
        color: $error500;

        .icon {
          svg path {
            fill: $error500;
          }
        }
      }
    }
    .add-players-btn {
      .icon {
        svg path {
          fill: $white;
        }
      }
    }
  }
  .section-content {
    height: calc(100% - 55px);
    .table-data {
      height: calc(100% - 22px);
      .no-data-message {
        width: 55%;
        margin: 0 auto;
        text-align: center;
        padding: 32px 0;
        p {
          line-height: 17px;
          text-align: center;
          color: $gray700;
        }
        .button {
          margin-top: 16px;
          span {
            font-size: 14px;
            line-height: 17px;
          }
          .icon {
            svg path {
              stroke: $white;
            }
          }
        }
      }
    }
  }
}
</style>
